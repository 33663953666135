import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import ClickButton from "../../Inputs/PageButtons";
import { DeleteRecord } from "../../SystemSettings/CommonLib";
import { Endpoints } from "../../SystemSettings/SystemSettings";

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});
export interface Aviator {
  id: number;
  name: string;
  userAccess: string;
  username: string;
}

export interface DisplayTableProps {
  token: string;
  data: Aviator[];
  headers: string[];
  setEditUserSelected: any;
  refreshList: any;
  setEmail: any;
  setName: any;
  setUserAccessSelected: any;
  setEditUserId: any;
}
interface FormFields {
  setEmail: any;
  setName: any;
  setUserAccessSelected: any;
  setEditUserId: any;
}

export default function CompanyAdminTable(props: DisplayTableProps) {
  const classes = useStyles();
  const accessLevel = [
    { name: "Normal User", id: "3" },
    { name: "Company Modarator", id: "2" },
    { name: "Company Organizer", id: "4" },
  ];
  function userAccessLevel(row: Aviator) {
    const userLevel = accessLevel.filter((user) => user.id == row.userAccess);
    return userLevel[0].name;
  }

  function setEditActive(row: Aviator) {
    props.setEditUserSelected(true);
    props.setEmail(row.username);
    props.setName(row.name);
    props.setUserAccessSelected(row.userAccess);
    props.setEditUserId(row.id);
  }
  // delete User
  function deleteAviator(id: number) {
    if (id) {
      DeleteRecord(
        Endpoints.tables.companyAdmin.delete,
        id,
        props.token,
        props.refreshList
      );
    }
  }

  function displayTable() {
    if (props.data.length > 0) {
      return props.data.map((row: Aviator, index: number) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.username}
          </TableCell>
          <TableCell component="th" scope="row">
            {userAccessLevel(row)}
          </TableCell>
          <TableCell component="th" scope="row">
            <ClickButton
              title={<BorderColorOutlinedIcon />}
              clickFunction={() => setEditActive(row)}
            />
            <ClickButton
              title={<DeleteForeverOutlinedIcon />}
              clickFunction={() => deleteAviator(row.id)}
              color="primary"
            />
          </TableCell>
        </TableRow>
      ));
    } else {
      return <div></div>;
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {props.headers.map((header: React.ReactNode, index: number) => {
              return <TableCell key={index}>{header}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>{props.data ? displayTable() : <></>}</TableBody>
      </Table>
    </TableContainer>
  );
}
