export const MissionTypes = [
  { value: "T", text: "T" },
  { value: "F", text: "F" },
  { value: "C", text: "C" },
  { value: "D", text: "D" },
  { value: "S", text: "S" },
  { value: "A", text: "A" },
  { value: "X", text: "X" },
];
export const ApacheSeat = [
  { value: "Back Seat", text: "Back Seat" },
  { value: "Front Seat", text: "Front Seat" },
];

export const IOSeat = [{ value: "IO Seat", text: "IO Seat" }];

export const AircraftSeat = [
  { value: "Left Seat", text: "Left Seat" },
  { value: "Right Seat", text: "Right Seat" },
];
export const RankNames = [
  { value: "WO1", text: "WO1" },
  { value: "CW2", text: "CW2" },
  { value: "CW3", text: "CW3" },
  { value: "CW4", text: "CW4" },
  { value: "CW5", text: "CW5" },
  { value: "2LT", text: "2LT" },
  { value: "1LT", text: "1LT" },
  { value: "CPT", text: "CPT" },
  { value: "MAJ", text: "MAJ" },
  { value: "LTC", text: "LTC" },
  { value: "PVT", text: "PVT" },
  { value: "PV2", text: "PV2" },
  { value: "PFC", text: "PFC" },
  { value: "SPC", text: "SPC" },
  { value: "CPL", text: "CPL" },
  { value: "SGT", text: "SGT" },
  { value: "SSG", text: "SSG" },
  { value: "SFC", text: "SFC" },
  { value: "MSG", text: "MSG" },
  { value: "1SG", text: "1SG" },
  { value: "CIV", text: "CIV" },
];
export const RLStatus = [
  { value: "RL1", text: "RL1" },
  { value: "RL2", text: "RL2" },
  { value: "RL3", text: "RL3" },
];

export const FAC = [
  { value: "FAC1", text: "FAC1" },
  { value: "FAC2", text: "FAC2" },
  { value: "FAC3", text: "FAC3" },
];

export const Months = [
  { value: "JAN", text: "JAN" },
  { value: "FEB", text: "FEB" },
  { value: "MAR", text: "MAR" },
  { value: "APR", text: "APR" },
  { value: "MAY", text: "MAY" },
  { value: "JUN", text: "JUN" },
  { value: "JUL", text: "JUL" },
  { value: "AUG", text: "AUG" },
  { value: "SEP", text: "SEP" },
  { value: "OCT", text: "OCT" },
  { value: "NOV", text: "NOV" },
  { value: "DEC", text: "DEC" },
];
export const AllDutyPositions = [
  {
    value: "AO",
    text: "AO",
  },
  {
    value: "CE",
    text: "CE",
  },
  {
    value: "CP",
    text: "CP",
  },
  {
    value: "FI",
    text: "FI",
  },
  {
    value: "IE",
    text: "IE",
  },
  {
    value: "ME",
    text: "ME",
  },
  {
    value: "MO",
    text: "MO",
  },
  {
    value: "MP",
    text: "MP",
  },
  {
    value: "NCT",
    text: "NCT",
  },
  {
    value: "PC",
    text: "PC",
  },
  {
    value: "PI",
    text: "PI",
  },
  {
    value: "SI",
    text: "SI",
  },
  {
    value: "SP",
    text: "SP",
  },
  {
    value: "UT",
    text: "UT",
  },
  {
    value: "XP",
    text: "XP",
  },
  {
    value: "IP",
    text: "IP",
  },
  {
    value: "AC",
    text: "AC",
  },
  {
    value: "AO",
    text: "AO",
  },
  {
    value: "EO",
    text: "EO",
  },
  {
    value: "IO",
    text: "IO",
  },
  {
    value: "SO",
    text: "SO",
  },
  {
    value: "FE",
    text: "FE",
  },
];
export const Aircraft = [
  {
    value: 0,
    airframe_name: "Unknown",
    text: "Unknown",
  },
  {
    value: 9,
    airframe_name: "RQ-7B",
    text: "RQ-7B",
    isSimulator: false,
  },

  {
    value: 11,
    airframe_name: "A139",
    text: "A139",
    isSimulator: false,
  },
  {
    value: 1,
    airframe_name: "AH64",
    text: "AH64",
    isSimulator: true,
  },
  {
    value: 10,
    airframe_name: "BELL 407",
    text: "BELL 407",
    isSimulator: true,
  },
  {
    value: 14,
    airframe_name: "Cessna 172",
    text: "Cessna 172",
    isSimulator: false,
  },
  {
    value: 4,
    airframe_name: "CH-47",
    text: "CH-47",
    isSimulator: true,
  },
  {
    value: 13,
    airframe_name: "EH-60",
    text: "EH-60",
    isSimulator: true,
  },
  {
    value: 15,
    airframe_name: "MH-6",
    text: "MH-6",
    isSimulator: true,
  },
  {
    value: 8,
    airframe_name: "MQ-5B",
    text: "MQ-5B",
    isSimulator: true,
  },
  {
    value: 3,
    airframe_name: "OH58",
    text: "OH58",
    isSimulator: true,
  },
  {
    value: 9,
    airframe_name: "RQ-7B",
    text: "RQ-7B",
    isSimulator: false,
  },
  {
    value: 7,
    airframe_name: "TH-67",
    text: "TH-67",
    isSimulator: true,
  },
  {
    value: 2,
    airframe_name: "UH-60",
    text: "UH-60",
    isSimulator: true,
  },
  {
    value: 12,
    airframe_name: "UH-72",
    text: "UH-72",
    isSimulator: true,
  },
  {
    value: 16,
    airframe_name: "C-12",
    text: "C-12",
    isSimulator: true,
  },
  {
    value: 17,
    airframe_name: "GROB 120",
    text: "GROB 120",
    isSimulator: true,
  },
  {
    value: 18,
    airframe_name: "MQ-1",
    text: "MQ-1",
    isSimulator: true,
  },
  {
    value: 19,
    airframe_name: "King Air 350",
    text: "King Air 350",
    isSimulator: true,
  },
  {
    value: 20,
    airframe_name: "King Air 200",
    text: "King Air 200",
    isSimulator: true,
  },
].sort((a, b) => (a.airframe_name > b.airframe_name ? 1 : -1));

export const AirFrameModels = [
  {
    text: "MC-12M/V",
    value: "49",
    airframe_id: 19,
  },
  {
    text: "RC-12X",
    value: "50",
    airframe_id: 20,
  },
  {
    text: "RB-7B",
    value: "44",
    airframe_id: 9,
  },
  {
    text: "A139",
    value: "20",
    airframe_id: 11,
  },
  {
    airframe_id: 2,
    text: "HH-60L",
    value: "39",
  },
  {
    text: "AH-64A",
    value: "2",
    airframe_id: 1,
  },
  {
    text: "AH-64D",
    value: "1",
    airframe_id: 1,
  },
  {
    text: "AH-64E",
    value: "32",
    airframe_id: 1,
  },
  {
    text: "Bell 407",
    value: "19",
    airframe_id: 10,
  },
  {
    text: "CH-47D",
    value: "7",
    airframe_id: 4,
  },
  {
    text: "CH-47F",
    value: "8",
    airframe_id: 4,
  },
  {
    text: "EH-60",
    value: "33",
    airframe_id: 13,
  },
  {
    text: "HH-60A",
    value: "3",
    airframe_id: 2,
  },
  {
    text: "HH-60H",
    value: "23",
    airframe_id: 2,
  },
  {
    text: "HH-60J",
    value: "4",
    airframe_id: 2,
  },
  {
    text: "HH-60L",
    value: "51",
    airframe_id: 2,
  },
  {
    text: "HH-60M",
    value: "6",
    airframe_id: 2,
  },
  {
    text: "MH-47G",
    value: "9",
    airframe_id: 22,
  },
  {
    text: "MH-60G",
    value: "24",
    airframe_id: 2,
  },
  {
    text: "MH-60K",
    value: "25",
    airframe_id: 2,
  },
  {
    text: "MH-60R",
    value: "26",
    airframe_id: 2,
  },
  {
    text: "MH-60S",
    value: "27",
    airframe_id: 2,
  },
  {
    text: "OH-58A",
    value: "13",
    airframe_id: 3,
  },
  {
    text: "OH-58A/C",
    value: "14",
    airframe_id: 3,
  },
  {
    text: "OH-58C",
    value: "15",
    airframe_id: 3,
  },
  {
    text: "OH-58D",
    value: "16",
    airframe_id: 3,
  },
  {
    text: "OH-58F",
    value: "17",
    airframe_id: 3,
  },
  {
    text: "SH-60B",
    value: "28",
    airframe_id: 2,
  },
  {
    text: "SH-60F",
    value: "29",
    airframe_id: 2,
  },
  {
    text: "TH-67A",
    value: "10",
    airframe_id: 7,
  },
  {
    text: "TH-67A+",
    value: "12",
    airframe_id: 7,
  },
  {
    text: "TH-67IS",
    value: "11",
    airframe_id: 7,
  },
  {
    text: "UH-60A",
    value: "5",
    airframe_id: 2,
  },
  {
    text: "UH-60A+",
    value: "21",
    airframe_id: 2,
  },
  {
    text: "UH-60L",
    value: "22",
    airframe_id: 2,
  },
  {
    text: "UH-60M",
    value: "18",
    airframe_id: 2,
  },
  {
    text: "MH-60M",
    value: "48",
    airframe_id: 2,
  },
  {
    text: "UH-72A",
    value: "31",
    airframe_id: 12,
  },
  {
    text: "VH-60N",
    value: "30",
    airframe_id: 2,
  },
  {
    text: "MH-47G",
    value: "34",
    airframe_id: 4,
  },
  {
    text: "MH-6M",
    value: "35",
    airframe_id: 15,
  },
  {
    text: "AH-6M",
    value: "36",
    airframe_id: 15,
  },
  { text: "MQ-1C", value: "45", airframe_id: 18 },
  { text: "MQ-1B", value: "46", airframe_id: 18 },
  { text: "MQ-1A", value: "47", airframe_id: 18 },

  {
    text: "AH-6R",
    value: "37",
    airframe_id: 15,
  },
  {
    text: "MH-6R",
    value: "38",
    airframe_id: 15,
  },
  {
    text: "C-12U",
    value: "40",
    airframe_id: 16,
  },
  {
    text: "GROB-120TP",
    value: "41",
    airframe_id: 17,
  },
].sort((a, b) => (a.text > b.text ? 1 : -1));

export const Endpoints = {
  customTags: {
    get: "custom-tags",
    post: "custom-tags",
    put: "custom-tags",
    delete: "custom-tags",
  },
  email: {
    support: "mail/contact",
    passwordReset: "mail/resetpassword",
  },
  lists: {
    dutyTable: "lists/dutypositions",
    airframes: "lists/airframe",
    airframeModel: "lists/airframe-model",
    dutyPositionList: "lists/dutyposition-list",
  },
  companyCalculator: {
    aircraftCurrency: "companycalc/currency",
    nvgCurrency: "companycalc/nvgcurrency",
  },
  totals: {
    semiannuals: "calculations/semi-annual",
    currency: "calculations/currency",
    nvgcurrency: "calculations/nvgcurrency",
    nvscurrency: "calculations/nvscurrency",
    careertotal: "calculations/totals",
    missiontypes: "calculations/mission",
    airframe: "calculations/airframe",
    model: "calculations/models",
    mode: "calculations/mode",
    duty: "calculations/duty",
  },
  tables: {
    aviationDutyPositions: {
      delete: "personal/dutypositions/delete/",
      createRecord: "personal/dutypositions/add",
    },
    personalProfile: {
      update: "personal/update",
      getRecord: "personal/get-record",
      password: "password/update",
    },
    flightLog: {
      add: "flightlog/add",
      update: "flightlog/update",
      searchdate: "flightlog/date-search",
      getcurrent: "flightlog/getcurrent",
      getall: "flightlog/getall",
      delete: "flightlog/delete/",
      getbyId: "get/",
    },
    armyLogbook: {
      searchdate: "armyflightlog/date-search",
      getall: "armyflightlog/getall",
    },
    simulator: {
      add: "simulatorlog/add",
      update: "simulatorlog/update",
      searchdate: "simulatorlog/date-search",
      getcurrent: "simulatorlog/getcurrent",
      getall: "simulatorlog/getall",
      getallbyuser: "simulatorlog/getallbyuser",
      delete: "simulatorlog/delete/",
      getbyId: "UPDATE",
    },
    companyAdmin: {
      getall: "companyadmin/view",
      add: "companyadmin/add",
      update: "companyadmin/update",
      delete: "companyadmin/delete/",
      authorized: "companyadmin/authorized",
    },
  },
};

export const Airframe = {
  AH64: 1,
  UH60: 2,
  OH58: 3,
};

export const DefaultDropDown = [{ text: "None", value: "None" }];
