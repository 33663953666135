import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  FltInputField,
  FltNumbericField,
  FltTextAreaField,
} from "../../Inputs/InputFields";
import DatePicker from "../../Inputs/DateFields";
import SimpleSelect from "../../Inputs/SelectOptions";
import {
  CreateRecord,
  LocalStorageInformation,
  FormatDate,
  UpdateRecord,
} from "../../SystemSettings/CommonLib";
import {
  MissionTypes,
  ApacheSeat,
  Airframe,
  AircraftSeat,
  Endpoints,
  AirFrameModels,
  DefaultDropDown,
  Aircraft,
  IOSeat,
} from "../../SystemSettings/SystemSettings";
import {
  PersonalJSON,
  IAddingSimulatorFlight,
  ISimulatorFlight,
} from "../../SystemSettings/ProjectInterfaces";
import CheckBoxField, { CheckBoxFieldProps } from "../../Inputs/CheckBoxField";
import ClickButton from "../../Inputs/PageButtons";
import Alert from "@material-ui/lab/Alert";

export interface FlightDetailsProps {
  token: string;
  isEditFlight: boolean;
  recordInformation?: ISimulatorFlight;
  updateOnEditSaveFunction?: any;
  flightData?: ISimulatorFlight[];
  setFlightData?: any;
  editArrayIndex?: number;
  setToken: any;
}
export interface IAirframeModels {
  value: string;
  text: string;
  airframe_id: number;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      marginBottom: 25,
      marginLeft: 10,
      marginRight: 10,
    },
    paragraph: {
      textAlign: "left",
    },
    picture: {
      maxWidth: "415px",
    },
  })
);

const SimulatorDetail: React.SFC<FlightDetailsProps> = (props) => {
  const [day, setDay] = useState(0);
  const [dutyPositionsList, setDutyPositionsList] = useState(DefaultDropDown);

  const [flightDate, setFlightDate] = useState("");
  const [flightRemarks, setFlightRemarks] = useState("");
  const [hood, setHood] = useState(0);
  const [night, setNight] = useState(0);
  const [nvg, setNvg] = useState(0);
  const [nvs, setNvs] = useState(0);
  const [personalProfile, setPersonalProfile] = useState<PersonalJSON>();
  const [selectAirframe, setSelectAirframe] = useState(0);
  const [selectAirframeModel, setSelectAirframeModel] = useState(0); // Airframe Model
  const [seat, setSeat] = useState("");
  const [selectDutyPosition, setSelectDutyPosition] = useState("");
  const [showAlertSaved, setshowAlertSaved] = useState(false); // Alert for saving data
  const [totalHours, setTotalHours] = useState(0);
  const [weather, setWeather] = useState(0);

  // Mission Data
  const [contact, setContact] = useState(false);
  const [tactics, setTactics] = useState(false);
  const [instruments, setInstruments] = useState(false);
  const [evaluation, setEvaluation] = useState(false);
  const [IORefresher, setIORefresher] = useState(false);
  const [HUDTraining, setHUDTraining] = useState(false);
  const [shipBoard, setShipBoard] = useState(false);
  const [mountainTraining, setMountainTraining] = useState(false);
  const [gunneryTraining, setGunneryTraining] = useState(false);
  const [CBRNtraining, setCBRNtraining] = useState(false);
  const [emergencyProceedures, setEmergencyProceedures] = useState(false);

  // List of all the useState functions for easy looping.
  const settersGroup = [
    setContact,
    setTactics,
    setInstruments,
    setEvaluation,
    setIORefresher,
    setHUDTraining,
    setShipBoard,
    setMountainTraining,
    setGunneryTraining,
    setCBRNtraining,
    setEmergencyProceedures,
  ];
  // Resetting the mission Data
  function resetMissionData() {
    settersGroup.map((func) => func(false));
  }
  const classes = useStyles();

  const missionDatas: CheckBoxFieldProps[] = [
    {
      currentValue: contact,
      valueSetter: setContact,
      name: "Contact",
    },
    {
      currentValue: tactics,
      valueSetter: setTactics,
      name: "Tactics",
    },
    {
      currentValue: instruments,
      valueSetter: setInstruments,
      name: "Instruments",
    },
    {
      currentValue: evaluation,
      valueSetter: setEvaluation,
      name: "Evaluation",
    },
    {
      currentValue: IORefresher,
      valueSetter: setIORefresher,
      name: "I/O Refresher",
    },
    {
      currentValue: HUDTraining,
      valueSetter: setHUDTraining,
      name: "HUD Training",
    },
    {
      currentValue: shipBoard,
      valueSetter: setShipBoard,
      name: "Shipboard Ops",
    },
    {
      currentValue: mountainTraining,
      valueSetter: setMountainTraining,
      name: "Mountain Training",
    },
    {
      currentValue: gunneryTraining,
      valueSetter: setGunneryTraining,
      name: "Gunnery Training",
    },
    {
      currentValue: CBRNtraining,
      valueSetter: setCBRNtraining,
      name: "CBRN Training",
    },
    {
      currentValue: emergencyProceedures,
      valueSetter: setEmergencyProceedures,
      name: "Emergency Proceedures",
    },
  ];

  // function called when we click the Add or Update button on the form.
  function handleSubmit() {
    const data: IAddingSimulatorFlight = {
      data: {
        lctDate: flightDate,
        Day: day.toString(),
        nite: night.toString(),
        lctNVS: nvs.toString(),
        lctHood: hood.toString(),
        lctSeat: seat,
        lctType: selectDutyPosition,
        lctRemarks: flightRemarks,
        airframe_id: +selectAirframe,
        airframe_model: selectAirframeModel,
        lctNVG: nvg.toString(),
        lctWeather: weather.toString(),
        lct_contact: contact.toString().toUpperCase(),
        lct_tactics: tactics.toString().toUpperCase(),
        lct_instruments: instruments.toString().toUpperCase(),
        lct_evaluation: evaluation.toString().toUpperCase(),
        lct_io_refresh: IORefresher.toString().toUpperCase(),
        lct_emergency: emergencyProceedures.toString().toUpperCase(),
        lct_hud: HUDTraining.toString().toUpperCase(),
        lct_shipboard: shipBoard.toString().toUpperCase(),
        lct_mountain: mountainTraining.toString().toUpperCase(),
        lct_gunnery: gunneryTraining.toString().toUpperCase(),
        lct_cbrn: CBRNtraining.toString().toUpperCase(),
      },
    };

    // If we are in edit mode use the update record otherwise to create record
    if (!props.isEditFlight) {
      CreateRecord(
        Endpoints.tables.simulator.add,
        props.token,
        data,
        savedAlert
      );
    } else {
      UpdateRecord(
        Endpoints.tables.simulator.update,
        props.token,
        data,
        savedAlert,
        props.recordInformation != undefined
          ? props.recordInformation.Flightid
          : undefined
      );
      updateFlightDataOnEditSave(data.data);
    }

    function savedAlert() {
      setshowAlertSaved(true);
      setTimeout(() => setshowAlertSaved(false), 5000);
    }
  }
  // Updating the flight table
  function updateFlightDataOnEditSave(newFlightRecord: ISimulatorFlight) {
    console.log(newFlightRecord);
    console.log(props.editArrayIndex);
    let updatedFlightData = props.flightData;
    if (updatedFlightData != undefined) {
      if (props.editArrayIndex != undefined) {
        const FlightID = updatedFlightData[props.editArrayIndex].Flightid;
        debugger;
        newFlightRecord["Flightid"] = FlightID;
        newFlightRecord["airframe_name"] = Aircraft.filter(
          (aircraft) => aircraft.value == newFlightRecord.airframe_id
        )[0].text;
        updatedFlightData[props.editArrayIndex] = newFlightRecord;
      }
    }

    console.log(updatedFlightData);
    props.setFlightData(updatedFlightData);
  }

  //Populating fields with Edit Data
  function populateEditData() {
    if (!props.recordInformation) {
      return;
    }
    const {
      Flightid,
      lctDate,
      Day,
      nite,
      lctNVS,
      lctHood,
      lctSeat,
      lctType,
      lctRemarks,
      airframe_id,
      tail_number,
      recovery,
      launch,
      lctNVG,
      lctWeather,
      lct_contact,
      lct_tactics,
      lct_instruments,
      lct_evaluation,
      lct_io_refresh,
      lct_emergency,
      lct_hud,
      lct_shipboard,
      lct_mountain,
      lct_gunnery,
      lct_cbrn,
      airframe_model,
    } = props.recordInformation;

    setContact(lct_contact.toLowerCase() == "true");
    setTactics(lct_tactics.toLowerCase() == "true");
    setInstruments(lct_instruments.toLowerCase() == "true");
    setIORefresher(lct_io_refresh.toLowerCase() == "true");
    setHUDTraining(lct_hud.toLowerCase() == "true");
    setShipBoard(lct_shipboard.toLowerCase() == "true");
    setMountainTraining(lct_mountain.toLowerCase() == "true");
    setGunneryTraining(lct_gunnery.toLowerCase() == "true");
    setCBRNtraining(lct_cbrn.toLowerCase() == "true");
    setEmergencyProceedures(lct_emergency.toLowerCase() == "true");
    setEvaluation(lct_evaluation.toLowerCase() == "true");
    setSelectAirframe(airframe_id ? airframe_id : 0);
    setFlightRemarks(lctRemarks);
    setSelectAirframeModel(airframe_model ? airframe_model : 0);
    setSeat(lctSeat);
    setSelectDutyPosition(lctType);
    setHood(+lctHood);
    setDay(+Day);
    setFlightDate(FormatDate(lctDate));
    setNight(+nite);
    setNvg(+lctNVG);
    setNvs(+lctNVS);
    setWeather(+lctWeather);
  }

  // Totaling up the AC Hours
  useEffect(() => {
    setTotalHours(
      Math.floor((+day + +night + +nvg + +nvs + +hood + +weather) * 10) / 10
    );
  }, [day, night, nvg, nvs, hood, weather]);

  // UseEffect to run onces on page load
  useEffect(() => {
    let flightPageInfo = new LocalStorageInformation(props.token);
    //Verifying that we have personal profile information in the local storage
    if (flightPageInfo.personalProfile) {
      setData(JSON.parse(flightPageInfo.personalProfile));
    } else {
      flightPageInfo.updatingPersonalProfile(setData, props.setToken);
    }

    flightPageInfo.updatingDutyPosition(setDutyData, props.setToken);

    function setData(json: any) {
      setPersonalProfile(json);
    }
    function setDutyData(json: any) {
      setDutyPositionsList(json);
    }

    if (props.isEditFlight) {
      //fetch the record to display
      populateEditData();
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-around">
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">
              {" "}
              Logging Simulator Flight Time: {totalHours}
            </Typography>

            <p className={classes.paragraph}>
              Enter your basic flight modes here. Use decimals to indicate
              partial hours.
            </p>
            <FltNumbericField
              fieldName="Day"
              valueSetter={setDay}
              currentValue={day}
            />

            <FltNumbericField
              fieldName="Night"
              valueSetter={setNight}
              currentValue={night}
            />
            <FltNumbericField
              fieldName="NVG"
              valueSetter={setNvg}
              currentValue={nvg}
            />
            <FltNumbericField
              fieldName="NVS"
              valueSetter={setNvs}
              currentValue={nvs}
            />

            <FltNumbericField
              fieldName="Hood"
              valueSetter={setHood}
              currentValue={hood}
            />
            <FltNumbericField
              fieldName="Weather"
              valueSetter={setWeather}
              currentValue={weather}
            />

            <Grid item xs={12} sm={12}>
              <DatePicker
                title="Flight Date"
                dateValue={flightDate}
                setDateValue={setFlightDate}
              />
              <SimpleSelect
                optionSets={dutyPositionsList}
                setOptionValue={setSelectDutyPosition}
                optionValue={selectDutyPosition}
                labelTitle={"Select Duty Position"}
              />
            </Grid>
            <Grid>
              <ClickButton
                clickFunction={handleSubmit}
                title={props.isEditFlight ? "Click to Update" : "Click to Add"}
              />
              {showAlertSaved ? <Alert severity="error">Saved</Alert> : <></>}
            </Grid>
          </Paper>

          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Aircraft Information:</Typography>

            <p className={classes.paragraph}>
              Please choose the Airframe, Model, and Seat you are flying.
            </p>
            <SimpleSelect
              optionSets={Aircraft.filter(
                (aircraft) => aircraft.isSimulator === true
              )}
              setOptionValue={setSelectAirframe}
              optionValue={selectAirframe}
              labelTitle={"Select Simulated Aircraft"}
            />

            <SimpleSelect
              optionSets={AirFrameModels.filter(
                (aircraft) => aircraft.airframe_id == selectAirframe
              )}
              setOptionValue={setSelectAirframeModel}
              optionValue={selectAirframeModel}
              labelTitle={"Select Simulated Aircraft Model"}
            />

            <SimpleSelect
              optionSets={
                selectAirframe == Airframe.AH64
                  ? [...ApacheSeat, ...IOSeat]
                  : [...AircraftSeat, ...IOSeat]
              }
              setOptionValue={setSeat}
              optionValue={seat}
              labelTitle={"Select Seat"}
            />
          </Paper>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Flight Notes</Typography>

            <p className={classes.paragraph}>
              Enter information about the flight that you would like to keep
              track of.
            </p>
            <FltTextAreaField
              fieldName="Flight Remarks"
              valueSetter={setFlightRemarks}
              currentValue={flightRemarks}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h6">Mission Information:</Typography>

            <p className={classes.paragraph}>
              Track some training events that you have done.
            </p>

            {missionDatas.map((missionData, index) => (
              <CheckBoxField
                key={index}
                valueSetter={missionData.valueSetter}
                name={missionData.name}
                currentValue={missionData.currentValue}
              />
            ))}
            <ClickButton
              clickFunction={() => {
                resetMissionData();
              }}
              title="Reset Mission Data"
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SimulatorDetail;
